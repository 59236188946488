// src/directives/i18nHtmlDirective.js

// export default {
//     mounted(el: any, binding: any) {
//       el.innerHTML = binding.value;
//     },
//     updated(el: any, binding: any) {
//       el.innerHTML = binding.value;
//     }
//   }

// export default {
//   mounted(el, binding) {
//     el.innerHTML = binding.value;
//   },
//   updated(el, binding) {
//     el.innerHTML = binding.value;
//   }
// }

export default {
  mounted(el: any, binding: any, vnode: any) {
    const appContext = vnode.ctx.appContext;
    if (!appContext || !appContext.config || !appContext.config.globalProperties.$t) {
      console.error("Cannot access i18n function");
      return;
    }
    const { $t } = vnode.ctx.appContext.config.globalProperties;
    if ($t) {
      el.innerHTML = $t(binding.value);
    }
  },
  updated(el: any, binding: any, vnode: any) {
    const { $t } = vnode.ctx.appContext.config.globalProperties;
    if ($t) {
      el.innerHTML = $t(binding.value);
    }
  }
}