import { defineStore } from 'pinia'

export const useMenuStore = defineStore('menu', {
    state: () => ({
        activePath: 'home'
    }),
    // getters: {
    //     activePath: (state) => state.activePath,
    // },
    actions: {
        setActivePath(path: string) {
            this.activePath = path;
        }
    },
    persist: true
});