// import VueI18n from "vue-i18n";

import { createI18n } from 'vue-i18n'
import moduleJPLanguage from '../langConfig/JP'
import moduleEnLanguage from '../langConfig/EN'
// Vue.use(VueI18n);

// 初始化默认字体
// let defaultLocal = localStorage.getItem("languageSet") || "zh-Chinese";
// 创建vue-i18n实例i18n
const i18n = createI18n({
    legacy: false,
    locale: 'jp', // 语言标识, 通过切换locale的值来实现语言切换,this.$i18n.locale
    messages: {
        "jp": moduleJPLanguage, // 中文语言包
        // "en": require("../langConfig/zh-Tibetan"), // 藏语语言包
        "en": moduleEnLanguage, // 藏语语言包

    },
});
// 暴露i18n
export default i18n;
