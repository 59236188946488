<template>
  <div id="menuIcon">
    <el-icon v-if="!menu.isMenuShow" :size="40" @click="toggleMobileNav"
      ><Menu
    /></el-icon>
    <el-icon class="closebtn" v-else :size="40" @click="toggleMobileNav"
      ><CloseBold
    /></el-icon>
  </div>
  <div id="nav">
    <div class="info" v-show="!menu.isMobile">
      <a href="tel:0482128230"
        ><div class="tel sps-button">TEL: 048-212-8230</div></a
      >
      <div class="contract sps-button" @click="toPage('/contact')">
        <el-icon style="vertical-align: middle"><Message /></el-icon
        ><span style="vertical-align: middle">{{
          $t("HomePage.Menu.contactText")
        }}</span>
      </div>
      <a>
        <div class="lang sps-button" style="margin-left: 2rem" @click="toggle">
          {{ $t("HomePage.toggleBtn") }}
        </div></a
      >
    </div>
    <div id="menu">
      <transition name="mobile-menu">
        <el-menu
          :default-active="store.activePath"
          :ellipsis="false"
          :mode="menu.mode"
          background-color="transparent"
          :class="{ 'sps-menu-vertical': menu.isMobile }"
          v-show="menu.isMenuShow"
          @select="handleSelect"
        >
          <el-menu-item class="sps-button" index="home">{{
            $t("HomePage.Menu.homeText")
          }}</el-menu-item>
          <el-menu-item class="sps-button" index="products">{{
            $t("HomePage.Menu.businessText")
          }}</el-menu-item>
          <el-menu-item class="sps-button" index="company">{{
            $t("HomePage.Menu.companyText")
          }}</el-menu-item>
          <el-menu-item class="sps-button" index="recruit">{{
            $t("HomePage.Menu.recruitment")
          }}</el-menu-item>
          <el-menu-item class="sps-button" index="works">{{
            $t("HomePage.Menu.workText")
          }}</el-menu-item>
          <el-menu-item class="sps-button" index="blog">{{
            $t("HomePage.Menu.blogText")
          }}</el-menu-item>

          <div class="info" v-show="menu.isMobile">
            <a href="tel:0482128230"
              ><div class="tel sps-button">TEL: 048-212-8230</div></a
            >
            <div class="contract sps-button" @click="toPage('/contact')">
              <el-icon style="vertical-align: middle"><Message /></el-icon
              ><span style="vertical-align: middle">{{
                $t("HomePage.Menu.contactText")
              }}</span>
            </div>
          </div>
        </el-menu>
      </transition>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { ref, reactive, onMounted, watchEffect } from "vue";
import { useMenuStore } from "@/store/menu";

import { useI18n } from "vue-i18n";
import { t } from "vue-i18n";
const { locale } = useI18n();
console.log(locale.value);
const store = useMenuStore();
const router = useRouter();
const menu = reactive({
  mode: "horizontal",
  isMenuShow: false,
});
const isCurrentEnglish = ref(true);
function toPage(page) {
  menu.isMenuShow = menu.isMobile ? false : true;
  window.scrollTo({ top: 0, behavior: "smooth" });
  router.push(page);
}
// function toggle() {
//   console.log(useI18n());
// }

const toggle = () => {
  // if (param1.length > 0) {
  //   locale.value = "en";
  // } else
  if (isCurrentEnglish.value) {
    locale.value = "en";
  } else {
    locale.value = "jp";
  }
  localStorage.setItem("lang", locale.value);
  isCurrentEnglish.value = !isCurrentEnglish.value;
};
window.addEventListener("load", checkScreen);
window.addEventListener("resize", checkScreen);
checkScreen();

function checkScreen() {
  menu.windowWidth = window.innerWidth;
  if (menu.windowWidth <= 700) {
    menu.isMobile = true;
    menu.isMenuShow = false;
    menu.mode = "vertical";
    return;
  }
  menu.isMobile = false;
  menu.isMenuShow = true;
  menu.mode = "horizontal";
  return;
}
function toggleMobileNav() {
  menu.isMenuShow = !menu.isMenuShow;
}

function handleSelect(key, keyPath) {
  menu.isMenuShow = menu.isMobile ? false : true;
  store.setActivePath(key);
  router.push(key === "home" ? "/" : "/" + key);
}
</script>

<style lang="scss">
.el-menu--horizontal {
  border-bottom: solid 0px var(--el-menu-border-color) !important;
}
.el-menu {
  border: 0 !important;
}
#nav {
  margin-bottom: 20px;
  margin-right: 15%;
  justify-content: end;
  .info {
    display: flex;
    justify-content: end;
    padding-right: 20px;
    div {
      display: flex;
    }
    .tel {
      background-color: rgba(0, 120, 180, 0.3);
      color: white;
      border: 1px solid #ffffff;
      padding: 10px 20px;
      height: 20px;
      font-weight: bold;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 0;
    }
    .lang {
      background-color: rgba(0, 120, 180, 0.3);
      color: white;
      border: 1px solid #ffffff;
      padding: 5px 10px;
      height: 20px;
      font-weight: bold;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 0;
      position: absolute;
      top: 0.3rem;
    }
    .contract {
      background-color: #ffffff;
      border: 1px solid #ffffff;
      padding: 10px 20px;
      height: 20px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 0;
      cursor: pointer;
    }
  }
  #menu {
    display: flex;
    justify-content: end;
    ul {
      list-style: none;
      display: flex;
    }

    .sps-menu-vertical {
      display: flex;
      position: fixed !important;
      flex-direction: column;
      background-color: rgba(0, 35, 41, 0.9);
      top: 0;
      right: 0;
      z-index: 998;
      width: 100%;
      height: 100%;
      padding-top: 40px;
      align-items: center;
    }
  }
}
#menuIcon {
  display: none;
  cursor: pointer;
  z-index: 999;
}
@media screen and (max-width: 700px) {
  #nav {
    justify-content: center !important;
    justify-items: center !important;
  }
  #menuIcon {
    display: block;
    position: fixed;
    right: 10px;
    top: 10px;
  }
  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 !important;
    width: 100%;
    margin-top: 40px;
    .tel {
      display: flex !important;
      background-color: transparent !important;
      color: white;
      border: 0 !important;
      padding: 0 !important;
      height: 20px;
      font-weight: bold;
      justify-content: center;
      align-items: center;
      border-radius: 0;
    }
    .contract {
      display: flex !important;
      background-color: #ffffff;
      border: 1px solid #ffffff;
      height: 20px;
      width: 260px;
      justify-content: center;
      align-items: center;
      border-radius: 20px !important;
      margin-top: 25px;
      cursor: pointer;
    }
  }
  .mobile-menu-enter-active,
  .mobile-menu-leave-active {
    transition: 1s ease all;
  }

  .mobile-menu-enter-from,
  .mobile-menu-leave-to {
    transform: translateY(-1250px);
  }

  .mobile-menu-enter-to {
    transform: translateY(0);
  }
}
</style>
