const moduleEnLanguage = {
    HomePage: {
        toggleBtn: "日本語",
        Menu: {
            contactText: "Contact Us",
            homeText: "Home",
            businessText: "Business Introduction",
            companyText: "Company Information",
            recruitment: "Recruitment Information",
            workText: "Our Works",
            blogText: "Blog",
            securityText: "Information security policy",
            copyRight: "Copyright © 2023 SPS Corp Ltd."
        },
        Top: {
            subTitle: "Delivering High-Quality Digital Artwork to the Gaming Industry",
            btnText: "The Metaverse Space of SPS",
            btnHowToText: "How to access the Metaverse Space of SPS"
        },
        Description: {
            sub1: ` We are a Japanese company with a focus on high-end game creation and a
            history of contract work in computer graphics. 
        Original artwork for characters, backgrounds, 3D characters, 3D
        backgrounds, game character design, modelling, animation, etc.are all
        areas in which we excel.
        We are capable of creating both human and monstrous figures.`,
            sub2: ` We are unable to disclose our current production works on our website
        due to confidentiality agreements.Please get in touch if this
        seems interesting to you.`,
        },
        News: {
            newsTitle: "Latest News",
            newsSubTitle: "Back issues",
        },
        footer: {
            title: "Company Address",
            subTitle: "Superps-japan ltd",
            address: "〒335-0004 Room 307 Annex Warabi, Warabi Central, 4 Chome-11-12 Saitama Japan",
            roadMap: {
                fir: "1. JR East Harumi station.",
                sec: "2. When I go to Nishiguchi rotary, I get three signals on the front road.",
                third: "3. Turn left at the roadside of the road to the left of the road.",
                fourth: "4. We have the third floor of the apartment.",
                fifth: "5. Enter the door in the parking lot."
            },
        }
    },
    Product: {
        introduce: {
            first: "SPS specializes in contract development of computer graphics. ",
            sec: `In March 2019, we established a company in Japan dedicated to advanced game production.`,
            third: `SPS specializes in contract development of computer graphics.`,
            fourth: `In March 2019, we established a company in Japan dedicated to advanced  game production.`,
            fifth: `We are unable to disclose our current production works on our website due to confidentiality agreements.`,
            sixth: "Please",
            seventh: "「contact us」",
            eighth: " if this seems interesting to you."
        },
        content: {
            first: "Main Business: Delivering High-Quality Digital Artwork to the Gaming Industry. ",
            sec: `Original artwork for characters, backgrounds, 3D characters, 3D backgrounds, game character design, modelling, animation, etc. are all areas in which we excel.We are capable of creating both human and monstrous figures.`,
            third: `Our high-quality industrial drafting services have been mistaken for photographs in a variety of contexts, including the manufacturing, medical, and gaming industries.`,
            fourth: `Our ultimate objective is to gain worldwide recognition for the superior quality we bring to each and every job we are entrusted with. We shall keep spreading hope and expanding our reach.`,
        },
        photo: {
            first: "CG and video production, direction and creation",
            second: "Software development for commercial use and games",
            third: "Management consulting and administrative services",
            fourth: "Translation/Interpretation (Chinese/Japanese/English/Korean)",
            fifth: "IDC Business (Server Installation/Cabling/Data Center Operations/Maintenance, etc.)",
            sixth: "Digital Marketing & Business Exchange Hub<br>(THE LOUNGE TOKYO)",
        },
        footer: {
            name: "Superps-japan ltd",
            address: "〒335-0004 Room 307 Annex Warabi, Warabi Central, 4 Chome-11-12 Saitama Japan",
        },
        footer2: {
            name: "Superps-japan ltd",
            address: "〒335-0004 Room 307 Annex Warabi, Warabi Central, 4 Chome-11-12 Saitama Japan",

        }
    },
    Company: {
        name: "Company Name",
        nameContent: "SPS合同会社",
        address: "Company Address",
        addressContent: "〒335-0004 Room 307 Annex Warabi",
        addressContent2: "Warabi Central, 4 Chome-11-12 Saitama Japan",
        date: "Establishment Date",
        dateContent: "March 8, 2019",
        capital: "Capital",
        capitalContent: "9.5 million yen",
        bank: "Transaction Bank",
        bankContent: "Mitsui Sumitomo Bank、Kawaguchi Shinkin Bank",
        Representative: "Company Representative",
        RepresentativeContent: "HAGIWARA NOBORU",
        Qualifications: "Qualifications",
        QualificationsContent: {
            first: "Paid Employment Placement Business",
            second: "Safety Action Declaration 2 Stars",
            third: "Business Innovation Plan Approval Qualification",
            fourth: "Standard Billing Business Registration Number: T4030003013240",
        },
        Partners: "Partners",
        PartnersContent: "",
    },
    Recruitment: {
        title: "Job Opening",
        form: {
            Profession: "Profession",
            ProfessionContent: `Engineer capable of creating game backgrounds and characters using
            MAYA/MAX/PHOTO SHOP software`,
            Description: "Job Description",
            DescriptionContent: {
                first: `Responsible for character design, 3D background modeling (using Maya),
                3D character modeling (using Maya)・`,
                second: `texture creation, image processing (using Photoshop), and various
                meetings with manufacturers and internal planners.`,
                third: " ■Operating System in Use: Windows",
                fourth: " ■Software Used: Maya, Photoshop",
            },
            Type: "Job Type",
            TypeContent: "Modeler",
            Year: "Years of Experience",
            YearContent: "2-3 years",
            Salary: "Salary",
            SalaryContent: "Starting from 250,000 yen",
            Employment: "Employment Type",
            EmploymentContent: "Contractual employee",
        },
        comment: {
            first: "Questions concerning the employment details? Please",
            second: "「contact us」",
            third: "with us.",
        }
    },
    Work: {
        title: "Our Works",
        Bg: "2D Background",
        Character: "2D Character",
        Model: "3D Modeling",
    },
    Security: {
        title: "Security policy",
        content: {
            detail: {
                first: "The SPS joint company (hereinafter our company) operates information security in all companies based on the following policy to protect the information received from customers and the information assets of the company from the threats such as accidents, disasters, and crimes, and to respond to the trust of customers and society.",
                second: "Our company will endeavor to improve and maintain information security.",
                third: "We set up an organization to maintain and improve information security and provide information security measures as official rules within the company. The organization shall be promoted by the representative of the company, the organization chief, the executive officer of our business, and the executive officer.",
                fourth: "Our employees will learn the knowledge and skills required for information security and ensure information security efforts.",
                fifth: `We comply with the laws, regulations, norms and contractual obligations related to information security and respond to our customers' expectations.
                `,
                sixth: `We will deal with the information security act violations, contract violations, and accidents.
                `,
            },
            title: {
                first: "1.Management responsibility",
                second: "2.Establishment of internal system",
                third: "3.Employee initiatives",
                fourth: "4.Compliance with laws and regulations and contract requirements",
                fifth: "5.Information security management",
            }
        },
        foot: {
            date: "Established on: June 24, 2020",
            name: "Superps-japan ltd",
            behalf: "On behalf of member Horikaga Masato"
        }
    },
    Contact: {
        name: "Name",
        tips: {
            Required: "Required",
            InputTips: "Please enter your name",
            errtip1: "Enter two or more characters.",
            InputTips2: "Please enter your email",
            errtip2: "Enter the correct email address.",

            InputTips3: "Please enter your phone number",
            InputTips4: "Please enter your title",
            errtip4: "Enter two or more characters.",

            InputTips5: "Please enter your inquiry details",
        },
        address: "Email address",
        phone: "Phone number",
        title: "Title",
        details: "Contact details",
        check: "Check the input contents and put in the check",
        send: "Send",
        placeholder: {
            input: "Please input",
            fill: "Please fill out your inquiry details"
        }
    }
};
export default moduleEnLanguage;
