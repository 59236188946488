<template>
    <section class="sidebutton">
        <img class="to-bottom-page" src="/img/scroll.png" v-show="showToBottomBtn" @click="backToBottom()">
        <img class="to-top-page" src="/img/toppage.png" v-show="showToTopBtn" @click="backToTop()">
    </section>
</template>
<style lang="scss" scoped>
.sidebutton {
    position: fixed;
    right: 10px;
    top: 0;
    z-index: 900;
    img {
        position: fixed;
        cursor: pointer;
    }
    .to-bottom-page {
        right: 100px;
        top: 50px;
    }
    .to-top-page {
        right: 100px;
        bottom: 50px;
    }
}

@media screen and (max-width: 768px) {
.sidebutton {
    .to-bottom-page {
        right: 20px !important;
    }
    .to-top-page {
        right: 20px !important;
    }
}
}
</style>
<script setup>
import { ref, onMounted, onUnmounted } from 'vue';


const showToTopBtn = ref(false);
const showToBottomBtn = ref(false);

const backToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
};

const backToBottom = () => {
    window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
    });
};
onMounted(() => {
  window.addEventListener('resize', handleScroll);
  handleScroll();
});

onUnmounted(() => {
  window.removeEventListener('resize', handleScroll);
});

const handleScroll = () => {
    if (window.pageYOffset > 100) {
        showToTopBtn.value = true;
    } else {
        showToTopBtn.value = false;
    }

    
    if (window.pageYOffset <= 400) {
        showToBottomBtn.value = true;
    } else {
        showToBottomBtn.value = false;
    }
};

onMounted(() => {
 document.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
 window.removeEventListener('scroll', handleScroll);
});



</script>