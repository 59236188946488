import { createRouter, createWebHistory } from 'vue-router'
import { useMenuStore } from '@/store/menu'
const routes = [
  {
    path: '/',
    name: 'home',
    component:()=> import('@/Pages/home/page-home.vue'),
    meta: {title: 'TOP | SPS合同会社'}
  },
  {
    path: '/products',
    name: 'products',
    component:()=> import('@/Pages/home/page-products.vue'),
    meta: {title: '事業案内 | SPS合同会社'}
  },
  {
    path: '/company',
    name: 'company',
    component:()=> import('@/Pages/home/page-company.vue'),
    meta: {title: '会社情報 | SPS合同会社'}
  },
  {
    path: '/recruit',
    name: 'recruit',
    component:()=> import('@/Pages/home/page-recruit.vue'),
    meta: {title: '採用情報 | SPS合同会社'}
  },
  {
    path: '/works',
    name: 'works',
    component:()=> import('@/Pages/home/page-works.vue'),
    meta: {title: '制作案例 | SPS合同会社'}
  },
  {
    path: '/blog',
    name: 'blog',
    beforeEnter() {location.href = 'https://superps-japan.halfmoon.jp/'},
    meta: {title: 'ブログ | SPS合同会社'}
  },
  {
    path: '/security',
    name: 'security',
    component:()=> import('@/Pages/home/page-security.vue'),
    meta: {title: '情報セキュリティ基本方針 | SPS合同会社'}
  },
  {
    path: '/contact',
    name: 'contact',
    component:()=> import('@/Pages/home/page-contact.vue'),
    meta: {title: 'お問い合わせ | SPS合同会社'}
  },
  {
    path: '/contact-complete',
    name: 'contact-complete',
    component:()=> import('@/Pages/home/page-contact-complete.vue'),
    meta: {title: 'お問い合わせ完了 | SPS合同会社'}
  },
  {
    path: '/backnumber',
    name: 'backnumber',
    component:()=> import('@/Pages/home/page-backnumber.vue'),
    meta: {title: 'バックナンバー | SPS合同会社'}
  },
  {
    path: '/backnumber/:id',
    name: 'backnumber-detail',
    component:()=> import('@/Pages/home/page-backnumber-detail.vue'),
    props: true,
    meta: {title: 'バックナンバー詳細 | SPS合同会社'}
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { left: 0, top: 0 }
    }
  }
})
const DEFAULT_TITLE = 'SPS合同会社';
router.afterEach((to, from) => {
  const store = useMenuStore();
  console.log('to', to)
  store.setActivePath(to.name)
  document.title = to.meta.title || DEFAULT_TITLE;
})
export default router
