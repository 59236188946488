<template>
  <Menu></Menu>
  <SideButton></SideButton>
  <router-view />
</template>

<script setup>
import Menu from "./components/sps-responsive-menu.vue";
import SideButton from "./components/sps-common-sidebutton.vue";
import { onMounted } from "vue";
import AOS from "aos";
import { useI18n } from "vue-i18n";
import { t } from "vue-i18n";
const { locale } = useI18n();
onMounted(() => {
  AOS.init();
  let lang = localStorage.getItem("lang");
  if (lang) {
    locale.value = lang;
  }
});
</script>

<style>
html {
  padding: 0px;
}

#app {
  position: relative;
}

.activeRouter {
  color: white !important;
  border-bottom: 3px solid white;
}
</style>
